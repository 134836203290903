<template>
  <div v-if="!isAccess" class="alert alert-warning my-3">{{ messageError }}</div>
  <div v-else id="dashboard-content">
    <div class="d-flex align-items-center">
      <nav>
        <ol class="breadcrumb p-0 bg-transparent">
          <li class="breadcrumb-item p-0 m-0">
            <router-link to="/user/penilaian" class="text-muted">
              Daftar Penilaian Buku
            </router-link>
          </li>
          <li class="breadcrumb-item active text-dark" aria-current="page">
            Detail Buku
          </li>
        </ol>
      </nav>
    </div>
    <h4>{{ bookPublisher.title }}</h4>

    <div class="mt-3">
      <div class="card bg-primary-50 mb-3 border-0 shadow-sm py-1 px-3">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-3">
              <img
                :src="
                  bookPublisher.cover_file ||
                    'https://image.web.id/images/Dummy.png'
                "
                class="w-100 rounded"
                alt="Cover"
              />
            </div>
            <div class="col-lg-9">
              <div class="row">
                <div class="col-lg-4 mb-4">
                  <div class="text-muted mb-2"><i class="fa fa-graduation-cap"></i> Jenjang</div>
                  <h6>{{ bookPublisher.level || "-" }}</h6>
                </div>
                <div class="col-lg-4 mb-4">
                  <div class="text-muted mb-2"><i class="fa fa-book"></i> Mata Pelajaran</div>
                  <h6>{{ bookPublisher.subject || "-" }}</h6>
                </div>
                <div class="col-lg-4 mb-4">
                  <div class="text-muted mb-2"><i class="fa fa-chalkboard"></i> Agenda</div>
                  <h6>{{ assessment_event || "-" }}</h6>
                </div>
                <div class="col-lg-4 mb-4">
                  <div class="text-muted mb-2">Tipe Buku</div>
                  <h6>{{ bookPublisher.book_type.toUpperCase() || "-" }}</h6>
                </div>
                <div class="col-lg-4 mb-4">
                  <div class="text-muted mb-2">Edisi</div>
                  <h6>{{ bookPublisher.edition || "-" }}</h6>
                </div>
                <div class="col-lg-4 mb-4">
                  <div class="text-muted mb-2">ISBN</div>
                  <h6>-</h6>
                </div>
                <div class="col-lg-4 mb-4">
                  <div class="text-muted mb-2">ID</div>
                  <h6>{{ bookPublisher.id || "-" }}</h6>
                </div>
                <div class="col-lg-4 mb-4">
                  <div class="text-muted mb-2">Kode</div>
                  <h6>{{ bookPublisher.code || "-" }}</h6>
                </div>
                <div class="col-lg-4 mb-4">
                  <div class="text-muted mb-2">Ilustrator</div>
                  <h6>{{ bookPublisher.illustrator || "-" }}</h6>
                </div>
                <div class="col-lg-4 mb-4">
                  <div class="text-muted mb-2">Author</div>
                  <h6>{{ bookPublisher.author || "-" }}</h6>
                </div>
                <div class="col-lg-4 mb-4">
                  <div class="text-muted mb-2">Editor</div>
                  <h6>{{ bookPublisher.editor || "-" }}</h6>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div>
            <h6 class="text-muted">Ringkasan Buku</h6>
            <p>{{ bookPublisher.synopsis || "-" }}</p>
          </div>
        </div>
      </div>
      <div class="d-flex mt-3 align-items-center">
        <router-link
          class="btn btn-secondary mr-3 text-white"
          :to="`/user/penilaian/hasil/${bookPublisher.id}`"
        >
          Hasil Penilaian
        </router-link>
        <router-link
          class="btn btn-outline-secondary"
          :to="`/user/penilaian/revisi/${bookPublisher.id}`"
        >
          Riwayat Revisi
        </router-link>
      </div>
    </div>

    <!-- Modal Popup Lihat Info -->
    <div
      class="modal fade"
      id="popupModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="popupModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="popupModalLabel">Detail</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>Hasil pengisian instrumen oleh staff dan penilai</p>
            <table class="table">
              <tbody>
                <tr>
                  <td>Catatan Umum</td>
                  <td>:</td>
                  <td>{{ notes }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import moment from "moment";
moment.locale("id");

export default {
  name: "User-Penilaian-Detail",
  data() {
    return {
      isAccess: true,
      messageError: "",
      assessment_event: "",
      notes: "",
      showAll: false,
    };
  },
  computed: {
    ...mapState(["bookPublisher", "bookSummary"]),
  },
  methods: {
    ...mapActions(["fetchBookByPublisher", "fetchBookSummary", "fetchAllEventPenilaian"]),
    setShowAll() {
      this.showAll = !this.showAll;
    },
    getDay(date) {
      return moment(date).format("dddd");
    },
    parseDate(date) {
      return moment(date).format("LL");
    },
  },
  created() {
    const id = this.$route.params.id;
    this.fetchBookByPublisher(id)
    .then(response => {
      const eventID = response.data.assessment_event_id
      if(response.status == 200) {
        this.fetchAllEventPenilaian(eventID).then(response => {
          let event = response.data.find(event => event.id == eventID)
          this.assessment_event = event.title
        })
      }
    })
    .catch(error => {
      if(error.response.status == 401) {
        this.messageError = "Buku tidak dapat diakses."
      }
      if(error.response.status == 404) {
        this.messageError = "Buku tidak ditemukan."
      }
      this.isAccess = false
    });
    this.fetchBookSummary(id);
  },
  beforeRouteEnter(_, __, next) {
    const { role_name } = JSON.parse(localStorage.getItem("user"));
    if (role_name === "Penerbit") next();
    else next("/user/home");
  },
};
</script>
